<style scoped lang="scss">
.wrap-reservation-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: baseline;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.select-table {
  font-size: 13px;
  font-family: Gibson;
  ::v-deep .v-icon {
    color: transparent !important;
    background-image: url("~@assets/icons/arrow-down.png");
    background-size: 30px;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    overflow: hidden;
  }
}
.v-select__slot ::v-deep .v-input__icon--append i {
  color: transparent !important;
}
.btn-reserv {
  width: 100%;
  display: block;
  border-radius: 12px;
  height: 54px;
  font-size: 13px;
  font-family: Gibson-Bold;
  position: relative;
  right: 5px;
  top: 2px;
}
.cancel-reserv {
  border: 1px solid white !important;
}
.v-dialog__content--active {
  z-index: 20001 !important;
  background: #16202f;
}
.reservation-band {
  padding: 25px;
  padding-bottom: 0px;
  color: white;
  font-size: 16px;
}
.reservation-band-message {
  margin-bottom: 3px;
}
.reservation-band-message__date,
.reservation-band-message__address {
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 500px) {
  .wrap-reservation-options {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
}
</style>

<template>
  <v-dialog v-model="display" fullscreen transition="dialog-bottom-transition" persistent>
    <ProfileHeader
      ref="profileHeader"
      :name="name"
      :email="email"
      :points="points"
      :image="image"
      :prevent-default-back="true"
      @onGoBack="$emit('close')"
    ></ProfileHeader>

    <v-container fluid grid-list-sm class="wrap-profile">
      <v-flex xs12 md10 sm12 class="profile-container">
        <div class="reservation-band">
          <p class="reservation-band-message">
            <v-clamp class="reservation-band-message__date" autoresize :max-lines="1">{{ date }}</v-clamp>
            <v-clamp
              class="reservation-band-message__address"
              autoresize
              :max-lines="1"
            >/ {{ address }}</v-clamp>
          </p>
          <p class="reservation-band-message">
            <b>{{ hour }} - {{ persons }} Personas</b>
          </p>
        </div>

        <SocialMediaList
          :phone="phone"
          :whatsapp="whatsapp"
          :instagram="instagram"
          :website="website"
          :facebook="facebook"
          @selectContact="handleSelectContact"
        ></SocialMediaList>
        <v-container v-if="loading === false">
          <div class="wrap-reservation-options">
            <v-select
              class="select-table"
              :items="mapNames(tables)"
              :disabled="confirmed || canceled"
              label="Asignar mesa"
              no-data-text="No hay mesas disponibles"
              solo
              @change="handleSelectTable"
            ></v-select>
            <v-btn
              class="btn-reserv confirm-reserv"
              color="primary"
              :disabled="confirmed || canceled || !tableSelected"
              @click="tryConfirmReserv"
            >Confirmar reserva</v-btn>
          </div>
          <v-btn
            class="btn-reserv cancel-reserv mb-5"
            color="secondary"
            :disabled="confirmed || canceled"
            dark
            @click="tryCancelReserv"
          >Cancelar reserva</v-btn>
        </v-container>
        <v-container v-if="loading" class="text-xs-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-container>
      </v-flex>
    </v-container>

    <v-snackbar
      v-model="sucess"
      :bottom="true"
      color="success"
      :left="true"
      :timeout="6000"
    >Operación completada con exito</v-snackbar>

    <v-snackbar
      v-model="error"
      :bottom="true"
      color="error"
      :left="true"
      :timeout="6000"
    >Ocurio un error, intenta mas tarde.</v-snackbar>
  </v-dialog>
</template>
<script>
import SocialMediaList from "@lists/SocialMediaList.vue";
import ProfileHeader from "@components/Headers/ProfileHeader";
import VClamp from "vue-clamp";
import requests from "@/helpers/requests";

export default {
  name: "ReservationState",
  components: { SocialMediaList, ProfileHeader, VClamp },
  props: {
    tables: {
      type: Array,
      default: () => []
    },
    noBack: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      display: this.open,
      hour: "",
      id: "",
      date: "",
      persons: "",
      address: "",
      phone: "",
      whatsapp: "",
      facebook: "",
      instagram: "",
      website: "",
      name: "",
      email: "",
      image: "",
      points: 0,
      error: false,
      confirmed: false,
      canceled: false,
      loading: false,
      showWhatsapp: false,
      sucess: false,
      showPhone: false,
      tableSelected: null,
      tableUnsuscription: null
    };
  },
  watch: {
    open(val) {
      this.display = val;
      if (this.$refs.profileHeader) {
        this.$refs.profileHeader.onPWA = false;
      }
      if (val === false) {
        this.confirmed = false;
        this.canceled = false;
        this.tableSelected = null;
      }
    }
  },
  methods: {
    handleSelectTable(table) {
      this.tableSelected = this.tables.find(item => item.name === table);
    },
    mapNames(data) {
      return data.map(item => item.name);
    },
    tryCancelReserv() {
      this.loading = true;
      requests
        .updateReservation(this.id, { status: "CANCEL" })
        .then(response => {
          this.loading = false;
          if (response.ok) {
            this.error = false;
            this.canceled = true;
            this.$emit("canceled", this.id);
          } else {
            this.error = true;
            this.canceled = false;
          }
        })
        .catch(error => {
          console.log("error ", error);
          this.error = true;
          this.loading = false;
          this.canceled = false;
        });
    },
    tryConfirmReserv() {
      console.log("confirm reserv");
      this.loading = true;
      requests
        .updateReservation(this.id, { status: "CONFIRM", table: this.tableSelected.id })
        .then(response => {
          this.loading = false;
          if (response.ok) {
            this.sucess = true;
            this.confirmed = true;
            this.$emit("confirmed", this.id, this.tableSelected.id);
          } else {
            this.error = true;
            this.confirmed = false;
          }
        })
        .catch(error => {
          console.log("error ", error);
          this.error = true;
          this.loading = false;
          this.canceled = false;
        });
    },
    handleSelectContact(val) {
      this.$emit("selectContact", val);
    }
  }
};
</script>
